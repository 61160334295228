import abyrtus from '@/assets/images/consultants/abyrtus.jpg';
import amichalska from '@/assets/images/consultants/amichalska.jpg';
import bosrodka from '@/assets/images/consultants/bosrodka.jpg';
import dwisniewski from '@/assets/images/consultants/dwisniewski.jpg';
import gstalica from '@/assets/images/consultants/gstalica.jpg';
import kbobak from '@/assets/images/consultants/kbobak.jpg';
import kklisz from '@/assets/images/consultants/kklisz.jpg';
import mwator from '@/assets/images/consultants/mwator.jpg';
import nparuzel from '@/assets/images/consultants/nparuzel.jpg';
import plech from '@/assets/images/consultants/plech.jpg';
import psiwy from '@/assets/images/consultants/psiwy.jpg';
import wpieczka from '@/assets/images/consultants/wpieczka.jpg';
import wtracz from '@/assets/images/consultants/wtracz.jpg';
import plasek from '@/assets/images/consultants/plasek.jpg';
import dpasieka from '@/assets/images/consultants/dpasieka.jpg';
import jnowak from '@/assets/images/consultants/jnowak.jpg';
import awojdylo from '@/assets/images/consultants/awojdylo.jpg'
import bgrudnik from '@/assets/images/consultants/bgrudnik.jpg'
import bsobieraj from '@/assets/images/consultants/bsobieraj.jpg'
import dpoloczek from '@/assets/images/consultants/dpoloczek.jpg'
import dzemirek from '@/assets/images/consultants/dzemirek.jpg'
import igarus from '@/assets/images/consultants/igarus.jpg'
import kmartyniak from '@/assets/images/consultants/kmartyniak.jpg'
import lpiechowiak from '@/assets/images/consultants/lpiechowiak.jpg'
import makrol from '@/assets/images/consultants/makrol.jpg'
import mnadzialek from '@/assets/images/consultants/mnadzialek.jpg'
import npilarski from '@/assets/images/consultants/npilarski.jpg'
import pmyslicki from '@/assets/images/consultants/pmyslicki.jpg'
import sgrudzinski from '@/assets/images/consultants/sgrudzinski.jpg'
import shenig from '@/assets/images/consultants/shenig.jpg'
import pczyrnek from '@/assets/images/consultants/pczyrnek.jpg'

export default [
    {
        email: "shenig@vehis.pl",
        img: shenig
    },
    {
        email: "sgrudzinski@vehis.pl",
        img: sgrudzinski
    },
    {
        email: "pmyslicki@vehis.pl",
        img: pmyslicki
    },
    {
        email: "pczyrnek@vehis.pl",
        img: pczyrnek
    },
    {
        email: "npilarski@vehis.pl",
        img: npilarski
    },
    {
        email: "mnadzialek@vehis.pl",
        img: mnadzialek
    },
    {
        email: "makrol@vehis.pl",
        img: makrol
    },
    {
        email: "lpiechowiak@vehis.pl",
        img: lpiechowiak
    },
    {
        email: "kmartyniak@vehis.pl",
        img: kmartyniak
    },
    {
        email: "igarus@vehis.pl",
        img: igarus
    },
    {
        email: "dzemirek@vehis.pl",
        img: dzemirek
    },
    {
        email: "dpoloczek@vehis.pl",
        img: dpoloczek
    },
    {
        email: "bsobieraj@vehis.pl",
        img: bsobieraj
    },
    {
        email: "bgrudnik@vehis.pl",
        img: bgrudnik
    },
    {
        email: "awojdylo@vehis.pl",
        img: awojdylo
    },
    {
        email: "abyrtus@vehis.pl",
        img: abyrtus
    },
    {
        email: "amichalska@vehis.pl",
        img: amichalska,
    },
    {
        email: "bosrodka@vehis.pl",
        img: bosrodka
    },
    {
        email: "dwisniewski@vehis.pl",
        img: dwisniewski
    },
    {
        email: "gstalica@vehis.pl",
        img: gstalica
    },
    { 
        email: "kbobak@vehis.pl", 
        img: kbobak }
    ,
    { 
        email: "kklisz@vehis.pl", 
        img: kklisz 
    },
    { 
        email: "mwator@vehis.pl", 
        img: mwator 
    },
    { 
        email: "nparuzel@vehis.pl", 
        img: nparuzel 
    },
    { 
        email: "plech@vehis.pl", 
        img: plech 
    },
    { 
        email: "psiwy@vehis.pl", 
        img: psiwy 
    },
    { 
        email: "wpieczka@vehis.pl", 
        img: wpieczka 
    },
    { 
        email: "wtracz@vehis.pl", 
        img: wtracz 
    },
    {
        email: "plasek@vehis.pl",
        img: plasek
    },
    {
        email: "jnowak@vehis.pl",
        img: jnowak
    },
    {
        email: "dpasieka@vehis.pl",
        img: dpasieka
    }
]