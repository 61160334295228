<script setup>
const props = defineProps({
  car: {
    type: Object,
    default: () => ({})
  },
  noButton: Boolean,
  imageOnScroll: {
    type: Number,
    default: null
  },
  noTop: Boolean,
  noAdjust: Boolean,
  offerView: Boolean
})

const {onChange, leasingDrawerOpened} = useLeasingParams();
const backendStore = useBackendStore();
const { isEmbed } = backendStore;
const openFinancingDrawer = () => {
  leasingDrawerOpened.value = true;
}
</script>

<template>
  <div class="py-4 select-none w-full lg:w-[300px] lg:min-w-[300px] sticky z-20 top-20 transition-all"
  :class="{'top-20': !leasingDrawerOpened, 'lg:top-60 xl:top-40':leasingDrawerOpened, '!top-0': isEmbed}">
    <CarCard :car="props.car"
             :imageOnScroll="imageOnScroll"
             @adjust="openFinancingDrawer"
             get-offer
             :noTop="noTop"
             :noButton="noButton"
             :noAdjust="noAdjust"
             :offerView="offerView"
    />
  </div>
</template>
